import styled from 'styled-components';
import {grey} from '../../../style/colors';

const Container = styled.div`
  height: 100px;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  border-top: 1px solid ${grey};
`;

export default Container;
