const columns = (t) => [
  {
    key: 'check',
    span: 1,
    label: t('selectRowCart'),
  },
  {
    key: 'catalogNo',
    label: t('catalogNumber'),
    sortable: false,
    span: 2,
  },
  {
    key: 'name',
    label: t('name'),
    sortable: true,
    span: 3,
  },
  {
    key: 'requestedQuantity',
    label: t('requestedQuantity'),
    sortable: true,
    align: 'center',
    span: 2,
  },
  {
    key: 'price',
    label: t('totalPriceWithVat'),
    sortable: true,
    align: 'center',
    span: 2,
  },
  {
    key: 'confirmedQuantity',
    label: t('confirmedQuantity'),
    sortable: true,
    align: 'center',
    span: 2,
  },
  {
    key: 'deliveryDate',
    label: t('estimatedDeliveryDate'),
    sortable: true,
    align: 'center',
    span: 2,
  },
  {
    key: 'actions',
    label: t('removeFromCart'),
    span: 1,
  },
];

export default columns;
