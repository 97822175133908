import React from 'react';
import PropTypes from 'prop-types';

// style components
import Container from './style/Container.style';
import Select from './../../../components/Select/Select';

// constants
import statuses from '../../constants/statuses.order.const';

// translations
import {useTranslation} from 'react-i18next';

const StatusSelect = ({orderId, status, onStatusChange}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <Select
        showBorder={false}
        value={status}
        unknown={t('error')}
        onChange={(value) => {
          onStatusChange(orderId, value);
        }}
        options={statuses(t)}
      >
        {t('status')}
      </Select>
    </Container>
  );
};

StatusSelect.propTypes = {
  orderId: PropTypes.string,
  status: PropTypes.number,
  onStatusChange: PropTypes.func,
};

export default StatusSelect;
