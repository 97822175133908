import React from 'react';
import PropTypes from 'prop-types';
import MultipleSelect from '../../../components/MultipleSelect/MultipleSelect';
import Container from './components/Container.style';

// translations
import {useTranslation} from 'react-i18next';

const OrdersTableFilters = ({onChange, options, multiValue}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <MultipleSelect
        value={multiValue}
        options={options}
        error={null}
        disabled={false}
        onChange={(value) => onChange(value)}
        multiple
      >
        {t('searchByOrderStatus')}
      </MultipleSelect>
    </Container>
  );
};

OrdersTableFilters.propTypes = {
  multiValue: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default OrdersTableFilters;
