import React, {useState} from 'react';
import PropTypes from 'prop-types';

// style components
import Text from '../../../Text/Text';
import InputsContainer from '../../components/InputsContainer';
import Input from '../../../Input/Input';
import Button from '../../../Button/Button';
import ChassisContainer from '../../components/ChassisContainer.style';

// redux
import {connect} from 'react-redux';

// translations
import {useTranslation} from 'react-i18next';

const SearchByChassis = ({chassis, onChassisSearch}) => {
  const [search, setSearch] = useState(chassis);
  const {t} = useTranslation();
  return (
    <ChassisContainer>
      <Text fontSize="18px">{t('chassisSearchLabel')}</Text>
      <InputsContainer>
        <Input
          rounded={false}
          width="75%"
          value={search}
          onChange={(value) => setSearch(value)}
          enterPressed={() => onChassisSearch(search)}
        >
          {t('chassisSearch')}
        </Input>
        <Button onClick={() => onChassisSearch(search)}>{t('search')}</Button>
      </InputsContainer>
    </ChassisContainer>
  );
};

SearchByChassis.propTypes = {
  onChassisSearch: PropTypes.func,
};

export default connect((state) => ({
  chassis: state.chassis.chassis,
}))(SearchByChassis);
