import React from 'react';
import {useTranslation} from 'react-i18next';

// Components
import Button from './components/Button';
import Container from './components/Container';
import Icon from './components/Icon';

// consts
import documents from './documents';

const ModelsDocuments = () => {
  const {t} = useTranslation();
  return (
    <Container>
      {documents(t).map((document, index) => (
        <Button
          key={index}
          onClick={() => {
            downloadDocument(document.url);
          }}
        >
          {document.name}
          <Icon
            className={`mdi mdi-${
              document.type === 'pdf' ? 'file-pdf-outline' : 'file-word-box'
            }`}
          ></Icon>
        </Button>
      ))}
    </Container>
  );
};

const downloadDocument = (url) => {
  window.open(url, '_blank');
};

ModelsDocuments.propTypes = {};

export default ModelsDocuments;
