import styled from 'styled-components';

const TyreButtonsContainer = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export default TyreButtonsContainer;
