import React from 'react';
import PropTypes from 'prop-types';

// components
import Button from './components/Button';
import ButtonContainer from './components/ButtonContainer';

// translations
import {useTranslation} from 'react-i18next';

// Components

const Logout = ({onLogout}) => {
  const {t} = useTranslation();
  return (
    <ButtonContainer>
      <Button onClick={onLogout}>{t('logout')}</Button>
    </ButtonContainer>
  );
};

Logout.propTypes = {
  onLogout: PropTypes.func,
};

export default Logout;
