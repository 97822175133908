import {green, lightGreen, orange, red} from '../../style/colors';

const getStatus = (statusCode, t) => {
  switch (statusCode) {
    case 0:
      return {
        text: t('closed'),
        color: green,
      };

    case -1:
      return {
        text: t('confirmed'),
        color: lightGreen,
      };
    case -2:
      return {
        text: t('inProgress'),
        color: orange,
      };
    case -3:
      return {
        text: t('canceled'),
        color: orange,
      };
    default:
      return {
        text: t('error'),
        color: red,
      };
  }
};

export default getStatus;
