import doc2 from '../../assets/documents/bg/Audi Winter leaflet_2023_web.pdf';
import doc1 from '../../assets/documents/bg/VW Winter leaflet_2023_web.pdf';

const documents = (t) => [
  {
    name: 'Зимна брошура VW 2023',
    url: doc1,
    type: 'pdf',
  },
  {
    name: 'Зимна брошура Audi 2023',
    url: doc2,
    type: 'pdf',
  },
];

export default documents;
