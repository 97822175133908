import React, {useState} from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import AdvancedText from './components/AdvancedText';
import AdvancedContainer from './components/AdvancedContainer';
import Select from '../Select/Select';
import Column from '../Grid/Column/Column';
import Row from '../Grid/Row/Row';
import Button from './components/Button';
import ButtonContainer from './components/ButtonContainer';
import Input from '../Input/Input';
import InputContainer from './components/InputContainer';
import ClearFilters from './components/ClearFilters';
import SeasonButtons from '../SeasonButtons/SeasonButtons';

// api
// import listVehicleMakesApi from '../../vehicle/api/list.api.vehicleMake';
// import listVehicleModelApi from '../../vehicle/api/list.api.vehicleModel';

// redux
import {connect} from 'react-redux';

// libs
// import capitalize from '../../lib/capitalize';

// translations
import {useTranslation} from 'react-i18next';

const AdvancedSearchFilters = ({
  advancedSearch,
  brands,
  advancedSearchFilters,
  onAdvancedSearchClick,
  onAdvancedFiltersChange,
  onAdvancedSearchSubmit,
  clearFilters,
  seasonButtons,
  onSeasonButtonClick,
  vehicleMake,
  vehicleModel,
  chassis,
}) => {
  const [chassisSearch, setChassisSearch] = useState(chassis);
  const [selectedVehicleMake, setSelectedVehicleMake] = useState(vehicleMake);
  const [selectedVehicleModel, setSelectedVehicleModel] =
    useState(vehicleModel);
  // const [vehicleMakes, setVehicleMakes] = useState([]);
  // const [vehicleModels, setVehicleModels] = useState([]);
  const {t} = useTranslation();

  // useEffect(() => {
  //   const fetchVehicleMakes = async () => {
  //     const response = await listVehicleMakesApi();
  //     const vehicleMakes = response.map((m) => ({
  //       label: capitalize(m.make),
  //       value: m._id,
  //     }));
  //     setVehicleMakes(vehicleMakes);
  //   };
  //   fetchVehicleMakes();
  // }, []);

  // useEffect(() => {
  //   const fetchVehicleModels = async () => {
  //     const response = await listVehicleModelApi({
  //       vehicleMake: selectedVehicleMake,
  //     });
  //     const vehicleModels = response.map((m) => ({
  //       label: capitalize(m.model),
  //       value: m._id,
  //     }));
  //     setVehicleModels(vehicleModels);
  //   };
  //   if (selectedVehicleMake) fetchVehicleModels();
  // }, [selectedVehicleMake]);

  // useEffect(() => {
  //   setChassisSearch(chassis);
  //   setSelectedVehicleMake(vehicleMake);
  //   setSelectedVehicleModel(vehicleModel);
  // }, [vehicleMake, vehicleModel, chassis]);

  const onSubmit = () => {
    onAdvancedSearchSubmit({
      chassis: chassisSearch,
      vehicleMake: selectedVehicleMake,
      vehicleModel: selectedVehicleModel,
    });
  };

  return (
    <Container>
      <AdvancedText
        enabled={
          chassisSearch ||
          selectedVehicleMake ||
          selectedVehicleModel ||
          advancedSearchFilters.brand ||
          advancedSearchFilters.design ||
          advancedSearchFilters.name ||
          advancedSearchFilters.rimSize ||
          advancedSearchFilters.width ||
          advancedSearchFilters.profile
        }
      >
        <span
          onClick={() => {
            onAdvancedSearchClick();
          }}
        >
          {t('advancedSearch')}
        </span>
      </AdvancedText>
      {advancedSearch ? (
        <AdvancedContainer>
          <Row>
            <Column size={1.5 / 12} />
            <Column size={3 / 12}>
              <SeasonButtons
                seasonButtons={seasonButtons}
                onClick={onSeasonButtonClick}
              />
            </Column>
          </Row>
          {brands && brands.length > 0 ? (
            <>
              <Row>
                <Column size={1.5 / 12} />
                <Column size={3 / 12}>
                  <Select
                    value={advancedSearchFilters.brand}
                    onChange={(value) => {
                      onAdvancedFiltersChange('brand', value);
                    }}
                    options={brands}
                  >
                    {t('selectedBrand')}
                  </Select>
                </Column>
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={advancedSearchFilters.design}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        onAdvancedFiltersChange('design', value);
                      }}
                    >
                      {t('design')}
                    </Input>
                  </InputContainer>
                </Column>
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={advancedSearchFilters.name}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        onAdvancedFiltersChange('name', value);
                      }}
                    >
                      {t('name')}
                    </Input>
                  </InputContainer>
                </Column>
              </Row>
              <Row margin>
                <Column size={1.5 / 12} />
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={advancedSearchFilters.ean}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        onAdvancedFiltersChange('ean', value);
                      }}
                    >
                      {t('ean')}
                    </Input>
                  </InputContainer>
                </Column>
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={advancedSearchFilters.dimensions}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        onAdvancedFiltersChange('dimensions', value);
                      }}
                    >
                      {t('dimensions')}
                    </Input>
                  </InputContainer>
                </Column>
              </Row>
              {/* <Row>
                <Column size={1.5 / 12} />
                <Column size={3 / 12}>
                  <Select
                    value={selectedVehicleMake}
                    onChange={(value) => {
                      setSelectedVehicleMake(value);
                      setSelectedVehicleModel(null);
                    }}
                    options={vehicleMakes}
                  >
                    {t('brandCar')}
                  </Select>
                </Column>
                <Column size={3 / 12}>
                  <Select
                    value={selectedVehicleModel}
                    onChange={(value) => {
                      setSelectedVehicleModel(value);
                    }}
                    options={vehicleModels}
                  >
                    {t('modelCar')}
                  </Select>
                </Column>
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={chassisSearch}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        setChassisSearch(value);
                      }}
                    >
                      {t('chassisSearch')}
                    </Input>
                  </InputContainer>
                </Column>
              </Row> */}
              <Row>
                <Column size={1.5 / 12} />
                <Column size={3 / 12}>
                  <ClearFilters>
                    <span
                      onClick={() => {
                        clearFilters();
                        setChassisSearch(chassis);
                        setSelectedVehicleMake(vehicleMake);
                        setSelectedVehicleModel(vehicleModel);
                      }}
                    >
                      {t('clearSearch')}
                    </span>
                  </ClearFilters>
                </Column>
              </Row>
            </>
          ) : (
            ''
          )}
          <ButtonContainer>
            <Button
              onClick={() => {
                onSubmit();
              }}
            >
              <i className="mdi mdi-magnify" /> {t('advancedSearch')}
            </Button>
          </ButtonContainer>
        </AdvancedContainer>
      ) : (
        ''
      )}
    </Container>
  );
};

AdvancedSearchFilters.propTypes = {
  advancedSearch: PropTypes.bool,
  advancedSearchFilters: PropTypes.object,
  brands: PropTypes.array,
  seasonButtons: PropTypes.array,
  onAdvancedSearchClick: PropTypes.func,
  onAdvancedFiltersChange: PropTypes.func,
  onAdvancedSearchSubmit: PropTypes.func,
  onSeasonButtonClick: PropTypes.func,
  clearFilters: PropTypes.func,
};

export default connect((state) => ({
  vehicleMake: state.vehicleModel.vehicleMake,
  vehicleModel: state.vehicleModel.vehicleModel,
  chassis: state.chassis.chassis,
}))(AdvancedSearchFilters);
