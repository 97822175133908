import {summer, winter, darkishGrey} from '../style/colors';

const seasonButtons = (t) => [
  {
    id: 1,
    _id: '',
    name: 'summer',
    text: t('summer'),
    icon: 'white-balance-sunny',
    color: summer,
    selected: false,
  },
  {
    id: 2,
    _id: '',
    name: 'winter',
    text: t('winter'),
    icon: 'snowflake',
    color: winter,
    selected: false,
  },
  {
    id: 3,
    _id: '',
    name: 'all season',
    text: t('allSeason'),
    icon: 'weather-partly-snowy',
    color: darkishGrey,
    selected: false,
  },
];

export default seasonButtons;
