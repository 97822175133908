import React from 'react';
import PropTypes from 'prop-types';
import Container from './style/Container.style';
import OrderRow from './style/OrderRow.style';
import Label from './style/Label.style';
import Data from './style/Data.style';
import StatusSelect from '../StatusSelect/StatusSelect';

// translations
import {useTranslation} from 'react-i18next';

const ExtendedOrderRow = ({orderId, status, onStatusChange, nestedRows}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <StatusSelect
        orderId={orderId}
        onStatusChange={onStatusChange}
        status={status}
      />
      {nestedRows.map((row, index) => (
        <OrderRow key={row._id}>
          <div>
            <Label>{t('seqNo')}</Label>
            <Data>{index + 1}.</Data>
          </div>
          <div>
            <Label>{t('orderName')}</Label>
            <Data>{row.name}</Data>
          </div>
          <div>
            <Label>{t('price')}</Label>
            <Data>{row.price}</Data>
          </div>
          <div>
            <Label>{t('quantity')}</Label>
            <Data>{row.quantity}</Data>
          </div>
          <div>
            <Label>{t('estimatedDeliveryDate')}</Label>
            <Data>{row.deliveryDate}</Data>
          </div>
          <div>
            <Label>{t('catalogNumber')}</Label>
            <Data>{row.catalogNumber}</Data>
          </div>
          <div>
            <Label>{t('ean')}</Label>
            <Data>{row.ean}</Data>
          </div>
        </OrderRow>
      ))}
    </Container>
  );
};

ExtendedOrderRow.propTypes = {
  orderId: PropTypes.string,
  nestedRows: PropTypes.array,
  onStatusChange: PropTypes.func,
};

export default ExtendedOrderRow;

// id: PropTypes.string,
// name: PropTypes.string,
// price: PropTypes.string,
// quantity: PropTypes.number,
// deliveryDate: PropTypes.string,
// catalogNumber: PropTypes.string,
// ean: PropTypes.string,
