import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import CheckBox from '../CheckBox/CheckBox';
import Button from './components/Button';
import Icon from './components/Icon';
import Textarea from '../Textarea/Textarea';
import Input from '../Input/Input';
import {Column} from '../Grid/Grid';
import OrderButton from './components/OrderButton';
import InputContainer from './components/InputContainer';

// translations
import {useTranslation} from 'react-i18next';

const Checkout = ({
  allSelected,
  onSelectAll,
  comment,
  onCommentChange,
  orderNo,
  onOrderNumberChange,
  onRemoveSelected,
  disabled,
  onSubmit,
}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <Column size={2 / 12}>
        <CheckBox selected={allSelected} onSelect={() => onSelectAll()}>
          {t('selectAll')}
        </CheckBox>
        <Button
          onClick={() => {
            onRemoveSelected();
          }}
        >
          <Icon className="mdi mdi-trash-can-outline" />
          {t('removeSelected')}
        </Button>
      </Column>
      <Column size={1 / 12}></Column>
      <Column size={6 / 12}>
        <Textarea
          minRows={1}
          value={comment}
          onChange={(value) => {
            onCommentChange(value);
          }}
        >
          {t('otherComments')}
        </Textarea>
      </Column>
      <Column size={1 / 12}></Column>
      <Column size={2 / 12}>
        <InputContainer>
          <Input
            required={true}
            rounded={false}
            value={orderNo}
            onChange={(value) => {
              onOrderNumberChange(value);
            }}
          >
            {t('orderNo')}
          </Input>
        </InputContainer>
        <OrderButton
          disabled={disabled}
          onClick={() => {
            onSubmit();
          }}
        >
          <Icon className="mdi mdi-email-check-outline" />
          {t('order')}
        </OrderButton>
      </Column>
    </Container>
  );
};

Checkout.propTypes = {
  allSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  orderNo: PropTypes.string,
  comment: PropTypes.string,
  onSelectAll: PropTypes.func,
  onRemoveSelected: PropTypes.func,
  onCommentChange: PropTypes.func,
  onOrderNumberChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default Checkout;
