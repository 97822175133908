import {combineReducers} from 'redux';

// Reducers
import auth from '../auth/redux/reducer';
import brand from '../brand/redux/reducer';
import cart from './reducer';
import department from '../department/redux/reducer';
import search from '../search/redux/reducer';
import searchDimensions from '../searchDimensions/redux/reducer';
import seasons from '../seasons/redux/reducer';
import vehicleModel from '../vehicle/redux/reducer';
import chassis from '../chassis/redux/reducer';

export default combineReducers({
  auth,
  cart,
  department,
  search,
  searchDimensions,
  seasons,
  brand,
  vehicleModel,
  chassis,
});
