import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import DetailsText from './components/DetailsText';

// brand images
import contiLogo from '../../assets/images/continental-tyre.png';
import semperitLogo from '../../assets/images/semperit-tyre.png';
import barumLogo from '../../assets/images/barum-tyre.png';
import Image from './components/Image';
import {useTranslation} from 'react-i18next';

const getTyreLogo = (brand) => {
  switch (brand.toLowerCase()) {
    case 'continental':
      return contiLogo;
    case 'semperit':
      return semperitLogo;
    case 'barum':
      return barumLogo;

    default:
      return '';
  }
};

const CatalogNumberWithLogo = ({catalogNo, brand}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <div>{catalogNo}</div>
      <Image src={getTyreLogo(brand)} />
      <DetailsText>{t('moreInformation')}</DetailsText>
    </Container>
  );
};

CatalogNumberWithLogo.propTypes = {
  catalogNo: PropTypes.string,
  brand: PropTypes.string,
};

export default CatalogNumberWithLogo;
