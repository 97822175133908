const getErrorMessageFromErrorCode = (code, t) => {
  switch (code) {
    case 303:
      return t(code);
    case 902:
      return t(code);
    case 906:
      return t(code);

    default:
      return `${t('errorHappened')} - ${t('error')} ${code}`;
  }
};

export default getErrorMessageFromErrorCode;
