import {Component} from 'react';

// assets
import continentalWinterContactDoc from '../../assets/documents/bg/Презентация-Continental-TS-870-P.pdf';

// components
import GuaranteeDocuments from '../../components/GuaranteeDocuments/GuaranteeDocuments';
import ModelsDocuments from '../../components/ModelsDocuments/ModelsDocuments';
import WarrantyDocuments from '../../components/WarrantyDocuments/WarrantyDocuments';

// style components
import Button from './style/Button';
import Icon from './style/Icon';
import DocumentTypeContainer from './style/DocumentTypeContainer';
import Title from './style/Title.style';
import Container from './style/Container.style';

// translations
import {withTranslation} from 'react-i18next';

class DocumentsContainer extends Component {
  downloadPriceList = () => {
    window.open(continentalWinterContactDoc, '_blank');
  };
  openSharepointLink = () => {
    window.open(
      'https://porscheholding-my.sharepoint.com/:u:/g/personal/alexander_velikov_porschebulgaria_bg/EZb3mR7EamVLiFudnJ1F-tIBrO_e5yYbFFSeFylxbtzAPA?email=zan%40devisium.com&e=0MSXjX',
      '_blank'
    );
  };
  render() {
    const t = this.props.t;
    return (
      <Container>
        <DocumentTypeContainer>
          <div>
            {' '}
            <Title>{t('miniPriceCatalog')}</Title>
            <Button
              onClick={() => {
                this.downloadPriceList();
              }}
            >
              {/* {t('miniPriceCatalog')} */}
              Презентация Continental TS 870 P
              <Icon className={`mdi mdi-file-pdf-outline`}></Icon>
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                this.openSharepointLink();
              }}
            >
              Обучение летни гуми Continental
            </Button>
          </div>
        </DocumentTypeContainer>

        <DocumentTypeContainer>
          <Title>{t('additionalGuarantee')}</Title>
          <GuaranteeDocuments />
        </DocumentTypeContainer>
        <DocumentTypeContainer>
          <Title>{t('warranty')}</Title>
          <WarrantyDocuments />
        </DocumentTypeContainer>
        <DocumentTypeContainer>
          <Title>{t('actualFlyers')}</Title>
          <ModelsDocuments />
        </DocumentTypeContainer>
      </Container>
    );
  }
}

export default withTranslation()(DocumentsContainer);
