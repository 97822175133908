import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const vehicleModel = (vehicleMake = '', vehicleModel = '') =>
  set({
    vehicleMake,
    vehicleModel,
  });
