const statuses = (t) => [
  {
    value: 0,
    label: t('closed'),
  },
  {
    value: -1,
    label: t('confirmed'),
  },
  {
    value: -2,
    label: t('inProgress'),
  },
  {
    value: -3,
    label: t('canceled'),
  },
];

export default statuses;
