import styled from 'styled-components';

import banner from '../../../assets/images/banner.jpeg';
import {white} from '../../../style/colors';

const Header = styled.div`
  width: 100%;
  height: 220px;
  background-image: url(${banner});
  background-size: cover;
  background-position: center;
`;

export const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  margin-top: 30px;
  display: block;
  color: ${white};
  font-weight: 600;
  font-size: 40px;
  line-height: 1.35em;
  margin-left: 20px;
  letter-spacing: 0;
`;

export default Header;
