const ediErrorCodeHandler = (errorCode, t) => {
  switch (errorCode) {
    case undefined:
      return undefined;
    case '0':
      return null;
    case '933':
      return t('933');

    default:
      return `${t('errorHappened')} - ${t('error')} ${errorCode}`;
  }
};

export default ediErrorCodeHandler;
