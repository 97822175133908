import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// components
import Text from '../../../Text/Text';
import InputsContainer from '../../components/InputsContainer';
import SelectContainer from '../../components/SelectContainer';
import Select from '../../../Select/Select';
import Button from '../../../Button/Button';
import SearchByModelContainer from '../../components/SearchByModelContainer.style';
import listVehicleMakesApi from '../../../../vehicle/api/list.api.vehicleMake';

// libs
import capitalize from '../../../../lib/capitalize';

// api
import listVehicleModelApi from '../../../../vehicle/api/list.api.vehicleModel';

// redux
import {connect} from 'react-redux';

// translations
import {useTranslation} from 'react-i18next';

const SearchByModel = ({vehicleMake, vehicleModel, onSearchByModel}) => {
  const [selectedVehicleMake, setSelectedVehicleMake] = useState(vehicleMake);
  const [selectedVehicleModel, setSelectedVehicleModel] =
    useState(vehicleModel);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const {t} = useTranslation();

  useEffect(() => {
    const fetchVehicleMakes = async () => {
      const response = await listVehicleMakesApi();
      const vehicleMakes = response.map((m) => ({
        label: capitalize(m.make),
        value: m._id,
      }));
      setVehicleMakes(vehicleMakes);
    };
    fetchVehicleMakes();
  }, []);

  useEffect(() => {
    const fetchVehicleModels = async () => {
      const response = await listVehicleModelApi({
        vehicleMake: selectedVehicleMake,
      });
      const vehicleModels = response.map((m) => ({
        label: capitalize(m.model),
        value: m._id,
      }));
      setVehicleModels(vehicleModels);
    };
    if (selectedVehicleMake) fetchVehicleModels();
  }, [selectedVehicleMake]);

  return (
    <SearchByModelContainer>
      <Text fontSize="18px">{t('chooseBrandAndModel')}</Text>
      <InputsContainer>
        <SelectContainer>
          <Select
            showBorder={false}
            value={selectedVehicleMake}
            onChange={(value) => {
              setSelectedVehicleMake(value);
              setSelectedVehicleModel(null);
            }}
            options={vehicleMakes}
          >
            {t('brand')}
          </Select>
        </SelectContainer>
        <SelectContainer>
          <Select
            showBorder={false}
            value={selectedVehicleModel}
            onChange={(value) => {
              setSelectedVehicleModel(value);
            }}
            options={vehicleModels}
          >
            {t('model')}
          </Select>
        </SelectContainer>

        <Button
          onClick={() =>
            onSearchByModel(selectedVehicleMake, selectedVehicleModel)
          }
        >
          {t('search')}
        </Button>
      </InputsContainer>
    </SearchByModelContainer>
  );
};

SearchByModel.propTypes = {
  onSearchByModel: PropTypes.func,
};

export default connect((state) => ({
  vehicleMake: state.vehicleModel.vehicleMake,
  vehicleModel: state.vehicleModel.vehicleModel,
}))(SearchByModel);
