import styled from 'styled-components';

const Logo = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export default Logo;
