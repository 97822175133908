const columns = (t) => [
  {
    key: 'catalogNumber',
    label: t('catalogNumber'),
    sortable: false,
    align: 'center',
    span: 3,
  },
  {
    key: 'name',
    label: t('titleAndAttributes'),
    sortable: false,
    span: 5,
    align: 'center',
  },
  {
    key: 'season',
    label: t('season'),
    sortable: false,
    span: 1,
    align: 'center',
  },
  {
    key: 'grossPrice',
    label: t('grossPrice'),
    sortable: true,
    span: 2,
    align: 'center',
  },
  {
    key: 'mail',
    label: '',
    span: 1,
  },
  {
    key: 'buy',
    label: t('purchase'),
    align: 'center',
    span: 2,
  },
];

export default columns;
