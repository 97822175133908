import doc1 from '../../assets/documents/bg/Формуляр-за-гаранция.doc';
import doc2 from '../../assets/documents/bg/Пример-за-снимка.jpg';
import doc3 from '../../assets/documents/bg/гаранционни-условия-Continental.docx';

const documents = (t) => [
  {
    name: 'Формуляр за гаранция',
    url: doc1,
    type: 'doc',
  },
  {
    name: 'Примерна снимка',
    url: doc2,
    type: 'jpg',
  },
  {
    name: 'гаранционни условия Continental',
    url: doc3,
    type: 'doc',
  },
];

export default documents;
