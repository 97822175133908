import styled from 'styled-components';

const LogoContainer = styled.div`
  width: 180px;
  height: 100px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
`;

export default LogoContainer;
