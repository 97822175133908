const createMail = (tyre) => {
  const mailTo = 'gregor.plohl@conti.de';
  const cc =
    '?cc= katya.petrova@porschebulgaria.bg; milen.ivanov@porschebulgaria.bg; alexander.velikov@porschebulgaria.bg';
  const subject = 'Заявка COPS-Online';
  const body = `Hello, %0D%0A %0D%0A Could you please tell me the delivery term for: ${tyre.catalogNumber}. %0D%0A %0D%0A (${tyre.design} - ${tyre.name})`;

  window.location.href = `mailto:${mailTo}${cc}&subject=${subject}&body=${body}`;
};

export default createMail;

// const createMail = (tyre) => {
//   const mailTo = 'cod.adria@conti.de';
//   const cc =
//     '?cc=vid.pucko@conti.de;andrej.supe@porsche.si;katka.kovacec@conti.de';
//   const subject = 'Povpraševanje COPS-online';
//   const body = `Pozdravljeni, %0D%0A %0D%0A prosim za informacijo o dobavljivosti pnevmatik s kataloško številko ${tyre.catalogNumber}. %0D%0A %0D%0A (${tyre.design} - ${tyre.name})`;

//   window.location.href = `mailto:${mailTo}${cc}&subject=${subject}&body=${body}`;
// };

// export default createMail;
