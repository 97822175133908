import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Table from '../../../components/Table/Table';
import {Row, Column} from '../../../components/Grid/Grid';

// Columns
import columns from './columns';

// translations
import {useTranslation} from 'react-i18next';

const Departments = ({
  search,
  department,
  departments,
  onBack,
  onDepartment,
  onSearch,
}) => {
  const {t} = useTranslation();
  return (
    <Container>
      {!!department && (
        <Row margin>
          <Column>
            <Button onClick={onBack}>{t('back')}</Button>
          </Column>
        </Row>
      )}
      <Row margin>
        <Column>{t('departmentContinue')}</Column>
      </Row>
      <Row margin>
        <Column>
          <Input value={search} onChange={onSearch} rounded={false}>
            {t('departmentSearch')}
          </Input>
        </Column>
      </Row>
      <Row>
        <Column>
          <Table
            columns={columns(t)}
            size="medium"
            headerSize="large"
            noMargin={false}
            labelSpace={false}
            theme="grey"
          >
            {(TableRow) =>
              departments.map((availableDepartment) => (
                <TableRow
                  key={availableDepartment._id}
                  rowClick={onDepartment(availableDepartment)}
                >
                  {(TableColumn) => [
                    <TableColumn key="name">
                      {availableDepartment.name}
                    </TableColumn>,
                  ]}
                </TableRow>
              ))
            }
          </Table>
        </Column>
      </Row>
    </Container>
  );
};

Departments.propTypes = {
  search: PropTypes.string,
  department: PropTypes.object,
  departments: PropTypes.array,
  onBack: PropTypes.func,
  onDepartment: PropTypes.func,
  onSearch: PropTypes.func,
};

export default Departments;
