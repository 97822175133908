import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ToggleWrapper from './components/ToggleWrapper.style';
import ToggleLabel from './components/ToggleLabel.style';
import ToggleInput from './components/ToggleInput.style';

class Toggle extends Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onToggleChange = (e) => {
    if (this.props.disabled) return;
    this.props.onChange(e.target.value);
  };

  render() {
    const {value} = this.props;
    return (
      <ToggleWrapper>
        <ToggleInput
          checked={value}
          onChange={this.onToggleChange}
          id="checkbox"
          type="checkbox"
        />
        <ToggleLabel htmlFor="checkbox"></ToggleLabel>
      </ToggleWrapper>
    );
  }
}

export default Toggle;
