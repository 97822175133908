// pages
import SearchPageName from '../../../pages/SearchPage/page';
import HomePageName from '../../../pages/HomePage/page';
import CartPageName from '../../../pages/CartPage/page';
import OrdersPageName from '../../../pages/OrdersPage/page';
import DocumentsPageName from '../../../pages/DocumentsPage/page';
// import StatisticsPageName from '../../../pages/StatisticsPage/page';

// routes
import homePageRoute from '../../../pages/HomePage/route';
import searchPageRoute from '../../../pages/SearchPage/route';
import cartPageRoute from '../../../pages/CartPage/route';
import ordersPageRoute from '../../../pages/OrdersPage/route';
import documentsPageRoute from '../../../pages/DocumentsPage/route';
// import statisticsPageRoute from '../../../pages/StatisticsPage/route';

const NavItems = (t) => [
  {
    id: 1,
    name: t('homePage'),
    route: homePageRoute,
    page: HomePageName,
  },
  {
    id: 2,
    name: t('searchPage'),
    route: searchPageRoute,
    page: SearchPageName,
  },
  {
    id: 3,
    name: t('documentsPage'),
    route: documentsPageRoute,
    page: DocumentsPageName,
  },
  {
    id: 6,
    name: t('ordersPage'),
    route: ordersPageRoute,
    page: OrdersPageName,
  },
  {
    id: 7,
    name: t('cartPage'),
    route: cartPageRoute,
    page: CartPageName,
  },
];

export default NavItems;
