import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import APP_LANG from './@i18n/lang.constant.i18n';
import en from './@i18n/en.json';
import sl from './@i18n/sl.json';
import bg from './@i18n/bg.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: APP_LANG(),
  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en,
    sl,
    bg,
  },
  ns: ['main'],
});

export default i18n;
