import styled from 'styled-components';

import {black, orange, darkGrey, white} from '../../style/colors';

const Button = styled.button`
  transition: all 0.5s ease-in-out 0s;
  background-color: ${orange};
  color: ${black};
  padding: 10px 30px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  &:hover {
    background-color: ${darkGrey};
    color: ${white};
  }
`;

export default Button;
