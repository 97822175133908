import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container, {
  SearchContainer,
  InputContainer,
  ButtonContainer,
} from './components/Container';
import Input from '../Input/Input';
import Button from './components/Button';
import CheckBox from '../CheckBox/CheckBox';
import CheckBoxContainer from './components/CheckBoxContainer';

// translations
import {useTranslation} from 'react-i18next';

const SearchFilters = ({
  value,
  searchOnlyInStock,
  onSearchOnlyInStockClick,
  onInputChange,
  onSearch,
}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <SearchContainer>
        <InputContainer>
          <Input
            value={value}
            onChange={(value) => onInputChange(value)}
            rounded={false}
            enterPressed={onSearch}
          >
            {t('searchByCatalogOrDimension')}
          </Input>
        </InputContainer>
        <CheckBoxContainer>
          <CheckBox
            selected={searchOnlyInStock}
            onSelect={onSearchOnlyInStockClick}
          >
            {t('searchOnlyInStock')}
          </CheckBox>
        </CheckBoxContainer>
      </SearchContainer>
      <ButtonContainer>
        <Button type="submit" onClick={() => onSearch()}>
          <i className="mdi mdi-magnify" /> {t('find')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

SearchFilters.propTypes = {
  value: PropTypes.string,
  searchOnlyInStock: PropTypes.bool,
  onSearchOnlyInStockClick: PropTypes.func,
  onInputChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default SearchFilters;
