import styled from 'styled-components';
import PropTypes from 'prop-types';
import {transitions} from 'polished';

import {white, darkGrey} from '../../../style/colors';

const DropDown = styled.div`
  width: 100%;
  max-height: 220px;
  background: ${white};
  border: 1px solid ${darkGrey};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: ${({dynamicError}) => (dynamicError ? '100%' : 'calc(100% - 18px)')};
  font-size: 13px;
  z-index: 1;
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  transform: ${({visible}) => (visible ? 'none' : 'translateY(10px)')};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  ${transitions(
    'transform .3s ease-in-out, visibility .3s ease-in-out, opacity .3s ease-in-out'
  )}
`;

DropDown.propTypes = {
  visible: PropTypes.bool,
};

export default DropDown;
