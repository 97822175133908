import React from 'react';
import PropTypes from 'prop-types';

// components
import SearchByDimensionContainer from '../../components/SearchByDimensionContainer';
import Text from '../../../Text/Text';
import InputsContainer from '../../components/InputsContainer';
import Input from '../../../Input/Input';
import SelectContainer from '../../components/SelectContainer';
import Select from '../../../Select/Select';
import Button from '../../../Button/Button';

// translations
import {useTranslation} from 'react-i18next';

const SearchByDimensions = ({
  brands,
  onBrandChange,
  selectedBrand,
  search,
  onSearch,
  onDimensionSearch,
}) => {
  const {t} = useTranslation();
  return (
    <SearchByDimensionContainer>
      <Text fontSize="18px">{t('quickSearchText')}</Text>
      <InputsContainer>
        <Input
          rounded={false}
          width="75%"
          value={search}
          onChange={(value) => onSearch(value)}
          enterPressed={onDimensionSearch}
        >
          {t('searchByCatalogOrDimension')}
        </Input>
        {brands && brands.length > 0 ? (
          <SelectContainer>
            <Select
              showBorder={false}
              value={selectedBrand}
              onChange={(value) => {
                onBrandChange(value);
              }}
              options={brands}
            >
              {t('brand')}
            </Select>
          </SelectContainer>
        ) : (
          ''
        )}
        <Button onClick={() => onDimensionSearch()}>{t('search')}</Button>
      </InputsContainer>
    </SearchByDimensionContainer>
  );
};

SearchByDimensions.propTypes = {
  brands: PropTypes.array,
  onBrandChange: PropTypes.func,
  selectedBrand: PropTypes.string,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  onDimensionSearch: PropTypes.func,
};

export default SearchByDimensions;
