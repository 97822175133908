import styled from 'styled-components';

const InputsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  max-width: 600px;
  height: 50px;
`;

export default InputsContainer;
