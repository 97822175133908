import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Actions
import {set as setAct} from '../../redux/actions';

// Alertify
import * as alertify from '../../../components/Alerts/lib/alertify';

// Api
import api from '../../../api/lib/getEverything.lib.api';
import listDepartmentsApi from '../../api/list.api.department';
import updateUserApi from '../../../user/api/update.api.user';

// Components
import Departments from '../../components/Departments/Departments';
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// Redux
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

class DepartmentsContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    showDepartments: PropTypes.bool,
    departments: PropTypes.array,
    department: PropTypes.object,
    children: PropTypes.node,
    user: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    search: '',
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) this.init();
  }

  init = () => {
    const {user} = this.props;
    if (!user) return;
    this.setState({search: ''});
    this.getDepartments();
  };

  getDepartments = async () => {
    const {user, dispatch} = this.props;

    dispatch(setAct({loading: true}));
    const {data: departments} = await api(listDepartmentsApi);
    const department =
      [...departments].find(({_id}) => _id === user.department) || null;
    dispatch(
      setAct({
        loading: false,
        departments,
        department,
        showDepartments: !department,
      })
    );
  };

  selectDepartment = (department) => async () => {
    const {loading, dispatch, t} = this.props;
    if (loading) return;

    dispatch(setAct({loading: true}));

    try {
      await updateUserApi({department: department._id});
      dispatch(setAct({loading: false, department, showDepartments: false}));
    } catch (error) {
      dispatch(setAct({loading: false}));
      alertify.error(t('error'));
    }
  };

  search = (search) => this.setState({search});

  back = () => {
    const {loading, department, dispatch} = this.props;
    if (loading || !department) return;
    dispatch(setAct({showDepartments: false}));
  };

  departments = () => {
    const {departments} = this.props;
    const {search: rawSearch} = this.state;
    const search = rawSearch.toLowerCase();
    return !!search.trim().length
      ? [...departments].filter(({name}) => name.toLowerCase().includes(search))
      : departments;
  };

  render() {
    const {loading, showDepartments, department, children, user} = this.props;
    const {search} = this.state;
    if (!user) return children;
    return loading ? (
      <FullScreenLoader />
    ) : showDepartments || !department ? (
      <Departments
        search={search}
        department={department}
        departments={this.departments()}
        onBack={this.back}
        onDepartment={this.selectDepartment}
        onSearch={this.search}
      />
    ) : (
      children
    );
  }
}

export default connect((state) => ({
  ...state.department,
  user: state.auth.user,
}))(withTranslation()(DepartmentsContainer));
