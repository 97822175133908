import React from 'react';
import PropTypes from 'prop-types';

import TyresContainer from './components/TyresContainer';

// translations
import {withTranslation} from 'react-i18next';

// components
import Container from './components/Container';
// import FeaturedTitleContainer, {
//   FeaturedTitle,
// } from './components/FeaturedTitle';
import FeaturedTyreContainer, {
  Image,
  Title,
} from './components/FeaturedTyreContainer';

const FeaturedTyres = ({tyres, t}) => (
  <Container>
    {/* <FeaturedTitleContainer>
      <FeaturedTitle>{t('featured')}</FeaturedTitle>
    </FeaturedTitleContainer> */}
    <TyresContainer>
      {tyres.map((tyre) => (
        <FeaturedTyreContainer key={tyre.id}>
          <Image src={tyre.img} />
          <Title>{tyre.title}</Title>
        </FeaturedTyreContainer>
      ))}
    </TyresContainer>
  </Container>
);

FeaturedTyres.propTypes = {
  tyres: PropTypes.array,
  addToCart: PropTypes.func,
};

export default withTranslation()(FeaturedTyres);
