import styled from 'styled-components';

const SelectContainer = styled.div`
  width: 50%;
  margin-right: 30px;
  border: 1px solid #000;
  background: #fff;
`;

export default SelectContainer;
