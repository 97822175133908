import doc1 from '../../assets/documents/bg/Продажби-2022.xlsx';

const documents = (t) => [
  {
    name: 'Продажби 2022',
    url: doc1,
    type: 'doc',
  },
];

export default documents;
