import React, {useState} from 'react';
import PropTypes from 'prop-types';

// components
import Container from './components/Container';
import ButtonContainer from './components/ButtonContainer';
import Text from '../Text/Text';
import {Row, Column} from '../Grid/Grid';
// import SquareButton from './components/SquareButton';
// import VerticalSeparator from './components/VerticalSeparator';
import TyreButtonsContainer from './components/TyreButtonsContainer';
import SeasonButton from '../SeasonButton/SeasonButton';
import SearchByDimensions from './Search/SearchByDimensions/SearchByDimensions';
import SearchByModel from './Search/SearchByModel/SearchByModel';
import SearchByChassis from './Search/SearchByChassis/SearchByChassis';

// translations
import {useTranslation} from 'react-i18next';

const QuickSearch = ({
  seasonButtons,
  onQuickSearchButtonClick,
  onTyreClick,
  search,
  onSearch,
  onDimensionSearch,
  brands,
  onBrandChange,
  selectedBrand,
  onChassisSearch,
  onModelSearch,
}) => {
  const [selected] = useState('catalogNumber');
  const {t} = useTranslation();

  return (
    <Container>
      <Text fontSize="24px" weight="bold">
        {t('quickSearch')}
      </Text>
      <ButtonContainer>
        {/* <SquareButton
          selected={selected === 'catalogNumber'}
          onClick={() => {
            setSelected('catalogNumber');
            onQuickSearchButtonClick('catalogNumber');
          }}
        >
          {t('catalogOrDimension')}
        </SquareButton> */}
        <Row>
          <Column size={4 / 12}>
            <TyreButtonsContainer>
              {seasonButtons.map((tyreBtn) => (
                <SeasonButton
                  key={tyreBtn.id}
                  id={tyreBtn.id}
                  text={tyreBtn.text}
                  icon={tyreBtn.icon}
                  selected={tyreBtn.selected}
                  onClick={() => {
                    onTyreClick(tyreBtn.id);
                  }}
                />
              ))}
            </TyreButtonsContainer>
          </Column>
        </Row>

        {/* <SquareButton
          selected={selected === 'brand'}
          onClick={() => {
            setSelected('brand');
          }}
        >
          {t('carModel')}
        </SquareButton>
        <SquareButton
          selected={selected === 'chassis'}
          onClick={() => {
            setSelected('chassis');
          }}
        >
          {t('chassisNo')}
        </SquareButton> */}
      </ButtonContainer>
      <Row>
        <Column size={7 / 12}>
          {selected === 'catalogNumber' && (
            <SearchByDimensions
              search={search}
              onSearch={onSearch}
              onDimensionSearch={onDimensionSearch}
              brands={brands}
              onBrandChange={onBrandChange}
              selectedBrand={selectedBrand}
            />
          )}
          {selected === 'brand' && (
            <SearchByModel onSearchByModel={onModelSearch} />
          )}
          {selected === 'chassis' && (
            <SearchByChassis onChassisSearch={onChassisSearch} />
          )}
        </Column>
        {/* <Column size={1 / 12}>
          <VerticalSeparator />
        </Column>
        <Column size={3 / 12}>
          <TyreButtonsContainer>
            {seasonButtons.map((tyreBtn) => (
              <SeasonButton
                key={tyreBtn.id}
                id={tyreBtn.id}
                text={tyreBtn.text}
                icon={tyreBtn.icon}
                selected={tyreBtn.selected}
                onClick={() => {
                  onTyreClick(tyreBtn.id);
                }}
              />
            ))}
          </TyreButtonsContainer>
        </Column> */}
      </Row>
    </Container>
  );
};

QuickSearch.propTypes = {
  search: PropTypes.string,
  seasonButtons: PropTypes.array,
  brands: PropTypes.array,
  selectedBrand: PropTypes.string,
  onSearch: PropTypes.func,
  onQuickSearchButtonClick: PropTypes.func,
  onDimensionSearch: PropTypes.func,
  onTyreClick: PropTypes.func,
  onBrandChange: PropTypes.func,
  onChassisSearch: PropTypes.func,
  onModelSearch: PropTypes.func,
};

export default QuickSearch;
