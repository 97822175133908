import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

// translations
import {useTranslation} from 'react-i18next';

// libs
import {formatPriceWithCurrency} from '../../lib/formatPriceWithCurrency';

const DisplayCartData = ({cartItemsCount, cartTotalPrice}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <div>
        {t('itemsInCart')} {cartItemsCount}
      </div>
      <div>
        {t('totalValue')} {formatPriceWithCurrency(cartTotalPrice)}
      </div>
    </Container>
  );
};

DisplayCartData.propTypes = {
  cartItemsCount: PropTypes.number,
  cartTotalPrice: PropTypes.any,
};

export default DisplayCartData;
